import React  from 'react';
import { Container, Button} from 'reactstrap';
import scrollToElement from '../../helpers/scrollToElement';

const Faster2 = () =>(
    <section className="faster-2">
        <Container>
            <h2>Dieta i treningi<br/>idealnie dopasowane</h2>
            <p>Prowadź zdrowy styl życia na swoich warunkach. Nie masz na coś ochoty? Wymieniaj przepisy, składniki lub całe jadłospisy i <strong>jedz tylko to, co naprawdę lubisz</strong>.</p>
            <p>Żyjesz w ciągłym biegu i nie masz kiedy pojechać na siłownie? Z aplikacją <strong>możesz</strong> ćwiczyć w domu i&nbsp;<strong>sama wybrać długość oraz trudność treningu</strong>.</p>
            <p>Przekonaj się jak <strong>niewiele trzeba, żeby zobaczyć efekty!</strong></p>

            <Button
                    color="red"
                    className="mt-4"
                    onClick={() =>
                        scrollToElement(
                            document.getElementById('download')
                        )
                    }
                >
                POBIERZ APLIKACJĘ
            </Button>
        </Container>
    </section>
);

export default Faster2;
