import React from "react";
import '../helpers/suppress-install-prompt';
import Layout from "../components/Layout";
import Seo from "../components/seo";
import Faster1 from "../components/Faster/Faster1";
import Faster2 from "../components/Faster/Faster2";
import Faster3 from "../components/Faster/Faster3";
import Faster4 from "../components/Faster/Faster4";
import Faster5 from "../components/Faster/Faster5";
import Faster6 from "../components/Faster/Faster6";
import Faster7 from "../components/Faster/Faster7";

import { graphql } from "gatsby";

const Page = ({ data }) => (
    <Layout page="faster" showMenu={false}>
        <Seo
            title="Szybciej"
        >
          <meta name="robots" content="noindex,nofollow" />
        </Seo>
        <Faster1
            bg1={data.bg1.childImageSharp.gatsbyImageData}
        />
        <Faster2/>
        <Faster3
            bg3={data.bg3.childImageSharp.gatsbyImageData}
        />
        <Faster4
            bg4={data.bg4.childImageSharp.gatsbyImageData}
        />
        <Faster5
            bg5={data.bg5.childImageSharp.gatsbyImageData}
        />
        <Faster6
            bg6={data.bg6.childImageSharp.gatsbyImageData}
            features={data.features.childImageSharp.gatsbyImageData}
            featuresFake={data.featuresFake.childImageSharp.gatsbyImageData}
        />
        <Faster7/>
    </Layout>
);

export default Page;


export const query = graphql`
    query {
        bg1: file(relativePath: { eq: "faster/bg1.jpg" }) {
            ...fluidImage1920
        },
        bg3: file(relativePath: { eq: "faster/bg3.jpg" }) {
            ...fluidImage1920
        },
        bg4: file(relativePath: { eq: "faster/bg4.jpg" }) {
            ...fluidImage1920
        },
        bg5: file(relativePath: { eq: "faster/bg5b.jpg" }) {
            ...fluidImage1920
        },
        bg6: file(relativePath: { eq: "faster/bg6.jpg" }) {
            ...fluidImage1920
        },
        features: file(relativePath: { eq: "faster/features.png" }) {
            ...fluidImage1920
        }
        featuresFake: file(relativePath: { eq: "faster/features-fake.png" }) {
            ...fluidImage1920
        }
    }
`;
